import { Link, NavLink } from 'react-router-dom';

import {
  AvatarContainer,
  Container,
  Icone,
  Item,
  UsuarioAvatar,
} from './styles';

import ComissaoIcon from '../../assets/Icons/ComissaoIcon';
import CorretoresIcon from '../../assets/Icons/CorretoresIcon';
import EmpreendimentosIcon from '../../assets/Icons/EmpreendimentosIcon';
import FaqIcon from '../../assets/Icons/FaqIcon';
import ImobiliariasIcon from '../../assets/Icons/ImobiliariasIcon';
import LotesIcon from '../../assets/Icons/LotesIcon';
import MarketplaceIcon from '../../assets/Icons/MarketplaceIcon';
import PedidosIcon from '../../assets/Icons/PedidosIcon';
import PremioIcon from '../../assets/Icons/PremiosIcon';
import PropostasIcon from '../../assets/Icons/PropostasIcon';
import RankingIcon from '../../assets/Icons/RankingIcon';
import DadosSinaisIcon from '../../assets/Icons/DadosSinaisIcon';

const MenuLateral = ({ currentPage, data }: any) => {
  const id_user = currentPage.data.user
    ? currentPage.data.user.id
    : 'sem id user';
  console.log(id_user);
  console.log('menu lateral tá batendo aq', currentPage);
  return (
    <Container>
      <UsuarioAvatar>
        <AvatarContainer>
          <img
            src={
              currentPage.data.user
                ? currentPage.data.user.avatar_url
                : 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Missing_avatar.svg/240px-Missing_avatar.svg.png'
            }
            style={{
              width: 128,
              height: 128,
              borderRadius: '50%',
              boxShadow: '0px 0px 1px 1px rgba(12,12,14,0.1)',
            }}
            alt="avatar"
          />
        </AvatarContainer>
        <h2>
          Bem vindo(a),{' '}
          {currentPage.data.user ? currentPage.data.user.name : ''}
        </h2>
      </UsuarioAvatar>

      <Item
        className={
          currentPage.pathname.includes('/painel/corretores') ? 'selected' : ''
        }
      >
        <NavLink to="/painel/corretores" className="link">
          <Icone>
            <CorretoresIcon />
          </Icone>
          Corretores
        </NavLink>
      </Item>

      <Item
        className={
          currentPage.pathname.includes('/painel/dados-sinais')
            ? 'selected'
            : ''
        }
      >
        <Link to="/painel/dados-sinais" className="link">
          <Icone>
            <DadosSinaisIcon />
          </Icone>
          Dados Sinais
        </Link>
      </Item>

      <Item
        className={
          currentPage.pathname.includes('/painel/imobiliarias')
            ? 'selected'
            : ''
        }
      >
        <Link to="/painel/imobiliarias" className="link">
          <Icone>
            <ImobiliariasIcon />
          </Icone>
          Imobiliarias
        </Link>
      </Item>

      <Item
        className={
          currentPage.pathname.includes('/painel/proposta') ? 'selected' : ''
        }
      >
        <Link to="/painel/propostas-admin" className="link">
          <Icone>
            <PropostasIcon />
          </Icone>
          Propostas
        </Link>
      </Item>

      {id_user === 'fb95ded4-d440-4701-86c0-998d1e8b6acb' ? (
        <Item
          className={
            currentPage.pathname.includes('/painel/comissao') ? 'selected' : ''
          }
        >
          <Link to="/painel/comissao" className="link">
            <Icone>
              <ComissaoIcon />
            </Icone>
            Comissão
          </Link>
        </Item>
      ) : (
        <></>
      )}

      <Item
        className={
          currentPage.pathname.includes('/painel/ranking') ? 'selected' : ''
        }
      >
        <Link to="/painel/ranking" className="link">
          <Icone>
            <RankingIcon />
          </Icone>
          Ranking
        </Link>
      </Item>

      <Item
        className={
          currentPage.pathname.includes('/painel/premios') ? 'selected' : ''
        }
      >
        <Link to="/painel/premios" className="link">
          <Icone>
            <PremioIcon />
          </Icone>
          Prêmios
        </Link>
      </Item>

      <Item
        className={
          currentPage.pathname.includes('/painel/marketplace') ? 'selected' : ''
        }
      >
        <Link to="/painel/marketplace" className="link">
          <Icone>
            <MarketplaceIcon />
          </Icone>
          Marketplace
        </Link>
      </Item>

      <Item
        className={
          currentPage.pathname.includes('/painel/pedidos') ? 'selected' : ''
        }
      >
        <Link to="/painel/pedidos" className="link">
          <Icone>
            <PedidosIcon />
          </Icone>
          Pedidos
        </Link>
      </Item>

      <Item
        className={
          currentPage.pathname.includes('/painel/empreendimentos')
            ? 'selected'
            : ''
        }
      >
        <Link to="/painel/empreendimentos" className="link">
          <Icone>
            <EmpreendimentosIcon />
          </Icone>
          Empreendimentos
        </Link>
      </Item>

      <Item
        className={
          currentPage.pathname.includes('/painel/leads') ? 'selected' : ''
        }
      >
        <Link to="/painel/leads" className="link">
          <Icone>
            <ImobiliariasIcon />
          </Icone>
          Leads
        </Link>
      </Item>

      <Item
        className={
          currentPage.pathname.includes('/painel/lotes') ? 'selected' : ''
        }
      >
        <Link to="/painel/lotes" className="link">
          <Icone>
            <LotesIcon />
          </Icone>
          Lotes
        </Link>
      </Item>

      <Item
        className={
          currentPage.pathname.includes('/painel/faq')
            ? 'selected ultimo-item'
            : 'ultimo-item'
        }
      >
        <Link to="/painel/faq" className="link">
          <Icone>
            <FaqIcon />
          </Icone>
          Faq
        </Link>
      </Item>
    </Container>
  );
};

export default MenuLateral;
