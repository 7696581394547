/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
import AppError from '../errors/AppError';
import api from './api';
import apiDocumentos from './api-documentos';
import { getToken } from './auth';

interface IlistProposalsAllEnterprise {
  status: string;
  limit: number;
  page: number;
  enterprise: string;
  customer: string;
  block: string;
  megaCod: string;
  created_at: string;
  updated_at: string;
}

class PropostasService {
  private headers: any;

  private headerFile: any;

  constructor() {
    this.headers = {
      Authorization: `Bearer: ${getToken()}`,
    };
    this.headerFile = {
      Authorization: `Bearer: ${getToken()}`,
      'Content-Type': 'multipart/form-data',
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async listPropostasJuridicas(id: string | number) {
    try {
      const { data } = await api.get(`proposalslegal/list/${id}`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async listAllPropostas() {
    try {
      const { data } = await api.get(`proposals/list`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async listAllPropostasImob() {
    try {
      const { data } = await api.get(`proposalsPhysical/imob/list`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });

      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async listAllPropostasAdmin({
    block,
    created_at,
    customer,
    enterprise,
    limit,
    megaCod,
    page,
    status,
    updated_at,
  }: IlistProposalsAllEnterprise) {
    try {
      const { data } = await api.get(`proposals/listAll`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
        params: {
          limit,
          page,
          status,
          enterprise,
          customer,
          block,
          megaCod,
          created_at,
          updated_at,
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async listPropostasFisicas(id: string | number) {
    try {
      const { data } = await api.get(`proposalsPhysical/list/${id}`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async getProposalParticipants(id: string | undefined) {
    try {
      const { data } = await api.get(`/proposals/participants/${id}`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async getProposalLegalById(id: string | undefined) {
    try {
      const { data } = await api.get(`/proposalslegal/find/${id}`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async getProposalPhysicalById(id: string | number) {
    try {
      const { data } = await api.get(`proposalsphysical/${id}`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async getProposalPhysicalByLandID(id: string | number) {
    try {
      const { data } = await api.get(`proposalsPhysical/findByLandID/${id}`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async getProposalsPhysical() {
    try {
      const { data } = await api.get(`proposalsphysical/list`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async getProposalsPhysicalRegional() {
    try {
      const { data } = await api.get(`proposalsPhysical/listByCodEmps`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async getProposalsLegal() {
    try {
      const { data } = await api.get(`proposalsLegal/list/`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      if (data) {
        return data;
      }
      return 'No Data Found';
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public async updateDocumentsProposalLegal(params: any, proposalId: any) {
    try {
      const formData = new FormData();

      // eslint-disable-next-line no-restricted-syntax
      for (let i = 0; i < params.length; i++) {
        formData.append(params[i].file.file.tag, params[i].file.file[0]);
      }

      // formData.append('proposal_id',proposalId)
      const { data } = await api.patch(
        `/proposalslegal/documents/${proposalId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async updateParticipante(params: any, participanteId: any) {
    try {
      const { data } = await api.patch(
        `/proposals/participants/${participanteId}`,
        params,
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`,
          },
        },
      );
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async updateConjuge(params: any, conjugeId: any) {
    try {
      const { data } = await api.patch(
        `/proposals/conjuges/${conjugeId}`,
        params,
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`,
          },
        },
      );

      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async analiseBru(params: any) {
    try {
      const { data } = await api.post('/analise_de_credito/avalia', params, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async deleteProposal(id: string | number) {
    try {
      const { data } = await api.delete(`/proposalsPhysical/${id}`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async updateProposta(params: any, propostaId: any) {
    try {
      const { data } = await api.patch(
        `/proposalsPhysical/${propostaId}`,
        params,
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`,
          },
        },
      );

      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async createFinancialSeries(params: any) {
    try {
      const { data } = await api.post(`/financialSeries/`, params, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });

      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async updateDocumentsProposalPhysical(
    params: any,
    proposalId: any,
    participantID: any,
  ) {
    try {
      const formData = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const file of params) {
        formData.append(file.tag, file.file);
        formData.append('deletado', file.deletado);
      }

      const { data } = await api.patch(
        `/proposalsPhysical/documents/${participantID}/${proposalId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async analysisResult(params: any) {
    try {
      const { data } = await api.post(`/proposals/analysisResult`, params, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async saleOccured(params: any) {
    try {
      const { data } = await api.post(`/proposals/saleOccured`, params, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async finishProposal(params: any) {
    try {
      const { data } = await api.post(`/proposals/`, params, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async getTokenPipe() {
    try {
      const { data } = await api.get(`proposals/api-tech/token`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async sendToPipe(params: any) {
    try {
      const { data } = await api.post(`/proposals/api-tech/pipefy`, params, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async defeatProposal(id: string, params: any) {
    try {
      const { data } = await api.patch(
        `proposalsPhysical/defeat/${id}`,
        params,
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`,
          },
        },
      );
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async resultAnalysiChatGPT(propostaId: string) {
    // const url = `https://fastapiautomacao.bairru.com.br/chatGpt/AnaliseDocumento/resultado/${propostaId}`;
    const url = `https://fastapiautomacao.bairru.com.br/AnaliseDocumento/GetResultado/${propostaId}`;

    const headers = {
      accept: 'application/json',
      token: 'd9520359df50574372fb8022fb56b90671cbf5c388132953a69b28d5ec37bfb6',
    };

    try {
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (error) {
      throw new Error('Erro ao chamar a rota GET');
    }
  }

  public async getEntryReports() {
    const url = `https://api-servicos-automacao.bairru.com.br/relatorios/parcelas-entradas`;

    const token = await this.getTokenAuthClientResume();

    const headers = {
      accept: 'application/json',
      Token: token,
      user: '',
    };

    try {
      const responseEntryReports = await axios.get(url, { headers });
      return responseEntryReports.data;
    } catch (error) {
      throw new Error('Erro ao chamar a rota getEntryReports');
    }
  }

  public async getTokenAuthClientResume() {
    try {
      const { data } = await axios.get(
        'https://apitech.bairru.com.br/auth/token',
        {
          headers: {
            usuario: 'leandro',
            senha: 'lcastelani325',
          },
        },
      );
      return data;
    } catch (err: any) {
      throw new Error('Erro ao obter o token');
    }
  }

  public async getDataClientResume(cpf: string) {
    try {
      const token = await this.getTokenAuthClientResume();

      const { data } = await axios.get(
        'https://apitech.bairru.com.br/dados_cliente/resumo',
        {
          headers: {
            token: token,
            doc: cpf,
          },
        },
      );

      return data;
    } catch (err: any) {
      throw new Error('Erro ao obter os dados do cliente resumo');
    }
  }

  public async calculator(params: any) {
    try {
      const { data } = await axios.post(
        `https://apitech.bairru.com.br/calculadora/parcelas_com_fluxo_generico`,
        params,
      );
      return data;
    } catch (err: any) {
      throw new Error('Erro no service da calculadora', err);
    }
  }

  public async descontoImplicito(id: string) {
    try {
      const token = await this.getTokenAuthClientResume();
      const { data } = await axios.get(
        `https://apitech.bairru.com.br/DescontoImplicito/consultaEmp`,
        {
          headers: {
            token: token,
            empreendimento: id,
          },
        },
      );
      return data;
    } catch (err: any) {
      throw new Error('Erro no service do desconto implicito', err);
    }
  }

  public async getFinancialSeriesByID(id_proposta: string) {
    try {
      const { data } = await api.get(
        `/financialSeries/listAll/${id_proposta}`,
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`,
          },
        },
      );
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async lancaMega(params: any) {
    try {
      const { data } = await api.post(
        `/proposals/mega/inserir-proposta-manager`,
        { ...params },
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`,
          },
        },
      );
      return data;
    } catch (err: any) {
      throw new AppError(err, err.response.data);
    }
  }

  public async deleteDocumentsPhysical(id: string, deletado: boolean) {
    const object = {
      id: id,
      deletado: deletado,
    };
    try {
      const { data } = await api.post(
        `proposalsPhysical/documents/delete`,
        object,
        {
          headers: {
            Authorization: `Bearer: ${getToken()}`,
          },
        },
      );
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async analiseDocument(cod_mega: string) {
    try {
      const { data } = await apiDocumentos.get(
        `/chatGpt/analisar_docs_background/?id_mega=${cod_mega}`,
      );
      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async createEvidence(params: any) {
    try {
      const formData = new FormData();
      formData.append('file', params.file);
      formData.append('user_id', params.user_id);
      formData.append('id_proposta', params.id_proposta);
      const { data } = await api.post(`/evidences/`, formData, {
        headers: {
          ...this.headers,
        },
      });

      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async getEvidence(id: any) {
    try {
      const { data } = await api.get(`/evidences/listByID/${id}`, {
        headers: {
          ...this.headers,
        },
      });

      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }

  public async moveCardReserva(
    idreserva_cv: number,
    idsituacao_destino: number,
  ) {
    try {
      const dataReserva = { idreserva_cv, idsituacao_destino };

      const { data } = await api.post('/cv/moveCard/reserva', dataReserva, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });

      return data;
    } catch (err) {
      console.log('error tentar mudar a reserva de situação');
      return null;
    }
  }

  public async getDataCV(documento: string) {
    try {
      const { data } = await api.get(`/cv/getDataCV/${documento}`, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
        },
      });

      return data;
    } catch (err) {
      console.log('error tentar buscar dados no getDataCV', err);
      return null;
    }
  }

  public async updateDocumentsCV(params: any, idreserva?: string) {
    try {
      const formData = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const file of params) {
        formData.append('files', file);
      }
      if (idreserva) {
        formData.append('idreserva', idreserva);
      }

      const { data } = await api.post(`/cv/enviarDocumento/reserva`, formData, {
        headers: {
          Authorization: `Bearer: ${getToken()}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      return data;
    } catch (err: any) {
      throw new AppError(err, err.statusCode);
    }
  }
}
export default new PropostasService();
