import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from '../pages/Login/index';
import Painel from '../pages/Painel/Painel';
import Proposta from '../pages/AcompanhamentoProposta';
import ContratoCp from '../pages/AcompanhamentoProposta/ContratoCp';
import RecoverPassword from '../pages/RecoverPassword';
import ChangePassword from '../pages/ChangePassword';
import DadosSinais from '../pages/DadosSinais';
import PageNotFound from '../pages/PageNotFound';
import EditarProposta from '../pages/AcompanhamentoProposta/EditarProposta';
import PainelImob from '../pages/AcompanhamentoPropostaImob/PainelImob/PainelImob';
import PropostasImob from '../pages/AcompanhamentoPropostaImob';
import PropostasRegional from '../pages/AcompanhamentoPropostaRegional';
import Leads from '../pages/AcompanhamentoPropostaImob/Leads';
import Comissao from '../pages/Comissao';
import CorretoresImob from '../pages/CorretoresImob';
import LeadsAdmin from '../pages/Leads/index';
import PoliticaDePrivacidade from '../pages/PoliticaDePrivacidade';
import EsclusaoDeConta from '../pages/ExclusaoDeConta';
import FormularioCorretorCV from '../pages/Corretores/FormularioCorretorCV';
import CorretorCriado from '../pages/Corretores/FormularioCorretorCV/TelaSucesso';
/* Não mexer na ordem das rotas :) */

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route
        component={CorretoresImob}
        path="/painel/imobiliaria/corretores"
        exact
      />
      <Route component={Proposta} path="/painel/proposta" exact />
      <Route
        component={ContratoCp}
        path="/painel/proposta/editar/contratoCp"
        exact
      />
      <Route
        component={PropostasImob}
        path="/painel/imobiliaria/proposta"
        exact
      />
      <Route
        component={PropostasRegional}
        path="/painel/regional/proposta"
        exact
      />
      <Route component={Leads} path="/painel/imobiliaria/leads" exact />
      <Route component={EditarProposta} path="/painel/proposta/editar" exact />
      <Route component={Comissao} path="/painel/comissao" exact />
      <Route component={RecoverPassword} path="/redefinir-senha" exact />
      <Route component={ChangePassword} path="/troca-senha" exact />
      <Route component={Login} path="/" exact />
      <Route component={Painel} path="/painel" />
      <Route
        component={PoliticaDePrivacidade}
        path="/politica-de-privacidade"
        exact
      />
      <Route component={EsclusaoDeConta} path="/exclusao-de-conta" exact />
      <Route component={FormularioCorretorCV} path="/criar-corretor" />
      <Route component={CorretorCriado} path="/corretor/criado" />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
